<template>
  <div class="Cfcheck">
    <div class="inputarea">
      <a-input-group compact>
        <a-select v-model:value="vupid">
          <a-select-option value="1">查主播</a-select-option>
          <!-- <a-select-option value="2">Option2</a-select-option> -->
        </a-select>
        <a-input-search
          enter-button
          v-model:value="uid"
          style="width: 50%"
          @search="serachinfo"
          placeholder="请输入uid"
        />
      </a-input-group>
    </div>

    <div class="checkinfo mt20" v-if="vupnowinfo">
      <div class="infonow">
        <p>当前数据</p>
        <div class="flexmode">
          <div class="infonowleft">
            <img :src="searchdata[0].vupCover" alt="" />
          </div>
          <div class="infonowright ml20">
            <h3>{{ vupnowinfo.channel.name }}</h3>
            <div class="mt5">
              <span class="textstyle">房间号：</span>
              <a-tag color="pink">{{ vupnowinfo.channel.roomId }}</a-tag>
            </div>
            <div class="mt5">
              <span class="textstyle">当前状态：</span
              ><span
                :class="[
                  vupnowinfo.channel.isLiving ? 'activecolor' : 'normalcolor',
                ]"
                >{{ vupnowinfo.channel.isLiving ? "正在直播" : "未开播" }}</span
              >
            </div>
            <div class="mt5">
              <span class="textstyle">uId：</span>
              <a-tag color="pink">{{ vupnowinfo.channel.uId }}</a-tag>
            </div>
            <div class="mt5 flexmode">
              <span class="textstyle">标签：</span>
              <div>
                <a-tag
                  color="#2db7f5"
                  v-for="(item, index) in vupnowinfo.channel.tags"
                  :key="index"
                  >{{ item }}</a-tag
                >
              </div>
            </div>
            <div class="mt5">
              <span class="textstyle">弹幕数：</span>
              <a-tag color="pink">{{
                vupnowinfo.channel.totalDanmakuCount
              }}</a-tag>
            </div>
            <div class="mt5">
              <span class="textstyle">总收益：</span>
              <a-tag color="pink">{{
                vupnowinfo.channel.totalIncome + "元"
              }}</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="infohistory mt20">
        <p>历史数据</p>
        <a-table :columns="columns" :data-source="vupnowinfo.lives"></a-table>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import axios from "axios";
import { message } from "ant-design-vue";
import { findVupName } from "../../api/vup/vup";
export default {
  setup() {
    const database = reactive({
      vupid: "1",
      uid: "",
      vupnowinfo: "",
      searchdata: {},
      columns: [
        {
          title: "直播标题",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "弹幕数",
          dataIndex: "danmakusCount",
          key: "danmakusCount",
        },
        {
          title: "观看次数",
          dataIndex: "watchCount",
          key: "watchCount",
        },
        {
          title: "收益",
          dataIndex: "totalIncome",
          key: "totalIncome",
        },
      ],
    });

    function serachinfo() {
      var config = {
        method: "get",
        url: `https://danmaku.suki.club/api/info/channel?cid=${database.uid}`,
        headers: {
          // "User-Agent": "Apifox/1.0.0 (https://www.apifox.cn)",
        },
      };

      axios(config)
        .then(function (res) {
          let { code, data } = res.data;
          if (code === 200) {
            database.vupnowinfo = data;
            findVupNames(data.channel.name);
          } else {
            message.error(res.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    async function findVupNames(name) {
      let { code, data } = await findVupName({
        name: name,
      });
      if (code === 200) {
        database.searchdata = data;
      }
    }
    return {
      ...toRefs(database),
      serachinfo,
    };
  },
};
</script>
<style lang="scss" scoped>
.Cfcheck {
  // background: #ccc;
  height: 100%;
  width: 100%;
  .inputarea {
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .checkinfo {
    .infonow {
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      position: sticky;
      top: 0;
      height: 320px;
      .infonowleft {
        height: 200px;
        width: 400px;
        // border: solid 1px #ccc;
        img {
          // height: 200px;
          width: 400px;
        }
      }
    }
    .infohistory {
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .normalcolor {
    color: red;
  }
  .activelcolor {
    color: green;
  }
  .textstyle {
    display: inline-block;
    width: 80px;
  }
}
</style>
